<template>
  <div
    :class="[ `has-background-${background}` ]"
    class="CXFeatures"
  >
    <div class="container container--padded">
      <div class="p-v-m has-text-weight-semibold">
        <go-back-button />
      </div>
    </div>
    <c-x-cover
      :heading="heading"
      :content="content"
      :cover-image="coverImage"
    />
    <c-x-navigation />
    <div class="container container--padded p-b-xxl">
      <slot />
      <div class="has-text-right m-b-xl">
        <router-link
          :to="{ name: 'cx.pricing' }"
          class="button is-primary is-wide"
        >
          {{ $t('pages.cx.see_pricing') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CXCover from '@/components/cx/CXCover'
import CXNavigation from '@/components/cx/CXNavigation'
import GoBackButton from '@/components/cx/GoBackButton'

/**
 * @module CXFeatures
 */
export default {
  name: 'CXFeatures',
  components: { CXCover, CXNavigation, GoBackButton },
  props: {
    type: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: 'grey-lighter'
    }
  },
  computed: {
    heading () { return this.$t(`pages.cx.${this.type}.heading`) },
    content () { return this.$t(`pages.cx.${this.type}.content`) },
    coverImage () { return this.$asset(`/images/cx/${this.type}/cover.png`) }
  }
}
</script>
