<script>
import CXBaseNavigation from '@/components/cx/CXBaseNavigation'

/**
 * Renders the {@see module:CXBaseNavigation} and provides default navigation items.
 * @module CXNavigation
 */
export default {
  name: 'CXNavigation',
  components: { CXBaseNavigation },
  computed: {
    navigationItems () {
      return [{
        to: { name: 'cx.features' },
        title: this.$t('components.cx_navigation.features')
      }, {
        to: { name: 'cx.pricing' },
        title: this.$t('components.cx_navigation.pricing')
      }, {
        to: { name: 'cx.contact_us' },
        title: this.$t('components.cx_navigation.contact_us')
      }]
    }
  },
  render (h) {
    return h(CXBaseNavigation, {
      props: {
        items: this.navigationItems
      },
      scopedSlots: {
        left: () => this.$slots.left,
        right: () => this.$slots.right
      }
    })
  }
}
</script>
