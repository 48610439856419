<template>
  <div class="CXBaseNavigation has-background-white p-v-m has-shadow is-size-5">
    <div class="container container--padded">
      <div class="is-flex is-aligned-middle">
        <div class="is-flex">
          <slot name="left" />
        </div>
        <div class="m-l-a is-flex has-text-weight-semibold">
          <slot name="right" />
          <router-link
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            active-class="has-text-primary"
            class="m-l-xl"
          >
            {{ item.title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Renders the base navigation for all CX pages.
 * TODO: Extract to an even more generic component later.
 * @module CXBaseNavigation
 */
export default {
  name: 'CXBaseNavigation',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
