<template>
  <section class="CXCover has-text-black has-background-grey-lighter">
    <div
      :style="{backgroundImage: `url(${coverImage})` }"
      class="container container--padded is-cover-image p-v-xxxl"
    >
      <div class="p-a-m">
        <heading size="1" color="black">
          {{ heading }}
        </heading>
        <div class="is-width-1/4-desktop is-width-1/2 m-b-l">
          {{ content }}
        </div>
        <router-link
          :to="{ name: 'cx.pricing' }"
          class="button is-primary has-text-sans is-wide"
        >
          {{ $t('pages.cx.see_pricing') }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
/**
 * @module CXCover
 */
export default {
  name: 'CXCover',
  props: {
    heading: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    coverImage: {
      type: String,
      default: ''
    }
  }
}
</script>
